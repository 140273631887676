<template>
  <div id="page_error">
    <section class="container">
      <h1 class="text-heading lg:text-heading-page text-primary text-center py-6 md:py-30px">
        {{ $t("500 - Internal Server Error") }}
      </h1>
      <div class="text-center py-6 md:py-30px">
        <button-outline color="secondary" :link="{to: '/'}">
          {{ $t('Go Home') }}
        </button-outline>
      </div>
    </section>
  </div>
</template>

<script>
import Error from '@vue-storefront/core/pages/Error'
import ButtonOutline from 'theme/components/theme/ButtonOutline'

export default {
  name: 'Error',
  components: {
    ButtonOutline
  },
  mixins: [Error]
}
</script>
